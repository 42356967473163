import React, { useEffect } from 'react'
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"


const PortfoliosByTag = ({ data, pageContext, location }) => {

  useEffect(() => {
    const elements = document.querySelectorAll('.portfolio-web-app .du-showcase-feature-img');
    Array.prototype.forEach.call(elements, function (ele) {
      const h0 = ele.clientHeight;
      const h1 = ele.children[0].clientHeight;
      const styleValue = "--t:" + (h0 - h1) + "px"
      ele.style.cssText += styleValue;
    });
    const divs = document.querySelectorAll('.portfolio-app-main');
    for (var i = 0; i < divs.length; i++) {
      divs[i].classList.add('active');
    }
    setTimeout(() => {
      for (var i = 0; i < divs.length; i++) {
        divs[i].classList.remove('active');
      }
    }, 1000);
  })

  const post = data.byTags.edges

  return (
    <Layout>
      <SEO title='Portfolio Filter by Technology' />
      <section className="mt-60 md:mt-65 lg:mt-20">
        <div className="container mx-auto">
          <h1 className="py-90 text-left font-poppings-bold text-rh1 sm:text-h0 xl:text-h2 text-term-primary">Portfolios Build in <span className='font-normal'> : </span><span className="text-cyan">{pageContext.name}</span></h1>
          <div className="blog-wrap grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-35 sm:gap-x-6-6 md:gap-y-45">
            {post ? post.map(({ node: post }, j) => (

              <div
                key={j}
                className={`portfolio-app-main ${post.acfPortfolio.portfolioType === "Mobile Application"
                  ? `portfolio-mobile-app`
                  : `portfolio-web-app`
                  }`}
              >
                <Link to={`${location.origin}/portfolio/${post.slug}`} className="block">
                  <div className="du-device-element">
                    <div className="du-elements">
                      <span className="du-elements-speaker du-elements-dots"></span>
                      <span className="du-elements-camera du-elements-dots"></span>
                      <span className="du-elements-dots"></span>
                    </div>
                    <div className="du-elements-switch">
                      <span className="du-elements-switch-lock"></span>
                      <span className="du-elements-switch-vol1"></span>
                      <span className="du-elements-switch-vol2"></span>
                    </div>
                    <div className="du-showcase-feature-img">
                      {post.acfPortfolio.portfolioType ===
                        "Mobile Application" ? (
                        <div className="du-mobile-imgs-main">
                          {post.photosForSlider.length > 0 &&
                            post.photosForSlider.map((image, i) => (
                              <img
                                key={i}
                                src={image}
                                className={`w-full block du-mobile-imges-main  du-mobile-img--${i + 1
                                  }`}
                              />
                            ))}
                        </div>
                      ) : (
                        <div
                          style={{
                            height:
                              post.featuredImage.node.localFile.childImageSharp
                                .gatsbyImageData.height <
                                post.featuredImage.node.localFile.childImageSharp
                                  .resize.height
                                ? post.featuredImage.node.localFile
                                  .childImageSharp.gatsbyImageData.height
                                : post.featuredImage.node.localFile
                                  .childImageSharp.resize.height,
                          }}
                        >
                          <GatsbyImage
                            image={getImage(
                              post.featuredImage.node.localFile
                            )}
                            alt={"showcase"}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
                <div className="du-showcase-content">
                  <Link to={post.slug} className="block">
                    <h3 className="pt-6 pb-4 text-p2 xl:text-h3 font-poppings-bold text-term-primary text-left">
                      {post.title}
                    </h3>
                  </Link>
                  <div className="flex flex-wrap">
                    {post.portfolioTags.nodes &&
                      post.portfolioTags.nodes.map((tag, t) => (
                        <div
                          className="du-tags relative text-p4 text-term-primary font-poppings-medium text-center px-4-3 py-2-3"
                          key={t}
                        >
                          {tag.name}
                          <Link
                            to={`${location.origin}/portfolio/tags/${tag.slug}`}
                            className="absolute right-0 bottom-0  p-2 cursor-default"
                          ></Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

            ))
              :
              "No Data Found!!"
            }

          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PortfoliosByTag

export const query = graphql`
query($id: String!){
    byTags: allWpPortfolio(
    filter: {portfolioTags: {nodes: {elemMatch: {id: {eq: $id}}}}}
    ) {
        edges {
            node {
                title
                acfPortfolio {
                    portfolioType
                    subTitle
                }
                photosForSlider
                portfolioTags {
                    nodes {
                        name
                        slug
                    }
                }
                industries {
                    nodes {
                        name
                        id
                        parentId
                    }
                }
                slug
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED)
                                resize {
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`